.followApp1 {
    text-align: center;

    .container-small {
        height: auto;
    }

    h3 {
        opacity: 0;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 44px;
        font-size: 36px;
    }
    h4 {
        opacity: 0;
        margin-bottom: 30px;
        font-weight: 300;
        font-size: 30px;
        line-height: 36px;
    }
    p {
        opacity: 0;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        margin-top: 50px;
    }
    img {
        height: 55vh;
        max-height: 620px;
    }
    @media (max-width: 1024px) and (min-width: 768px) {
        img {
            height: auto;
        }
    }
    @media screen and (max-width: 767px) {
        .container-small {
            padding: 40px 24px;
        }
        h3 {
            font-weight: 500;
            font-size: 36px;
            line-height: 44px;
        }
        h4 {
            font-size: 17px;
            line-height: 21px;
        }
        p {
            margin-bottom: 50px;
            font-size: 16px;
            line-height: 20px;
        }
        img {
            max-height: 312px;
        }
    }
}