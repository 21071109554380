.followApp2-1 {
    .dino {
        position: absolute;
        top: 115px;
        left: 0;
        max-height: 100vh;
        width: 100vw;
    }
    .assistant {
        scale: 0;
        position: absolute;
        bottom: 20px;
        left: 30px;

        width: 560px;

        img {
            position: relative;
        }
        p {
            font-size: 20px;
            line-height: 25px;
            position: absolute;
            top: 30px;
            left: 50px;
            width: 380px;
        }
    }

    @media screen and (max-height: 850px) {
        .dino {
            top: 30px;
        }
    }
    @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        height: 100vh;

        .dino {
            position: initial;
            padding-bottom: 100px;
        }
        .assistant {
            bottom: 90px;
            left: 15px;
            width: 320px;

            p {
                font-size: 12px;
                line-height: 15px;
                top: 12px;
                left: 43px;
                width: 200px;
            }
        }
    }
}