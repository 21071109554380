.followApp9 {
    .container {
        padding: 80px 45px 45px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            font-family: Free Pen; 
            font-weight: 400;
            font-size: 154px;
            line-height: 48px;
        }

        .arrows {
            max-width: 1000px;
            display: flex;
            gap: 120px;
            justify-content: space-between;

            margin-top: 85px;
            margin-bottom: 30px;
        }

        .assistant {
            scale: 0;
            position: absolute;
            width: 87vh;
            bottom: 0;
            right: 14vw;
    
            img {
                position: relative;
            }
            p {
                text-align: center;
                font-weight: 300;
                font-size: 3.8vh;
                line-height: 123%;
                position: absolute;
                bottom: 28%;
                left: 10%;
                width: 70%;
            }
        }
        @media screen and (max-width: 1080px) {
            position: relative;
            overflow-x: hidden;
            h2 {
                font-size: 100px;
            }
            .arrows {
                min-width: 1000px;
            }
        }
        @media (max-width: 1024px) and (min-width: 768px) {
            .answers-everything {
                gap: 15px;
            }
            .assistant {
                width: 74vh;
            }
        }
        @media screen and (max-width: 767px) {
            position: relative;
            overflow-x: hidden;
            padding: 40px 30px;
            height: 100dvh;

            h2 {
                font-size: 53px;
                margin-bottom: 15px;
            }
            .arrows {
                display: none;
            }
            .assistant {
                position: absolute;
                width: 45vh;
                bottom: auto;
                top: 335px;
                right: auto;
                left: 55%;

                p {
                    font-size: 2.1vh;
                    bottom: 18%;
                    left: 5%;
                }
            }
        }
        @media screen and (max-width: 450px) {
            .assistant {
                right: -10vw;
            }
        }
    }
}