.followApp12 {
    h3 {
        font-weight: 700;
        font-size: 39px;
        line-height: 48px;
    }
    p {
        font-family: Delight Winter;
        font-weight: 400;
        font-size: 26px;
        line-height: 34px;
    }
    .content {
        position: relative;
        margin-top: -55px;
        p {
            max-width: 390px;
        }
       .puls {
            max-width: 97%;
       }
       .top-left {
            position: absolute;
            top: 4%;
            left: 4%;
       }
       .arrow-top-left {
            position: absolute;
            top: 26%;
            left: 24%;
            width: 27%;
            transform: rotate(11deg);
       }

       .top-right {
            position: absolute;
            top: 3%;
            right: 3%;
       }
       .arrow-top-right {
            position: absolute;
            top: 23%;
            right: 10%;
            width: 11%;
            transform: rotate(81deg);
       }

       .bottom-right {
            position: absolute;
            bottom: 7%;
            right: 23%;
       }
       .arrow-bottom-right {
            position: absolute;
            bottom: 24%;
            right: 26%;
            width: 10%;
            transform: rotate(337deg);
       }

       .title-left {
            position: absolute;
            top: 39%;
            left: 3%;
            font-family: "Free Pen";
            font-weight: 400;
            font-size: 92px;
            line-height: 48px;
       }
       .title-right {
            position: absolute;
            right: -5.5%;
            top: 38%;
            font-weight: 700;
            font-size: 39px;
            line-height: 48px;
       }
    }
    .camera {
        position: absolute;
        bottom: 45px;
        left: 50px;

        .camera-inner {
            position: relative;
        }
    }
    .assistant-smile {
        scale: 0;
        position: absolute;
        bottom: 15%;
        right: -218px;
        max-width: 190px;
        width: 18vh;
    }

    .elem {
        position: absolute;
        bottom: 0;
        right: 0;
        max-height: 400px;
        z-index: -1;
    }
    @media screen and (max-width: 1200px) {
        .content {
            p {
                font-size: 21px;
                line-height: 29px;
            }
            .title-left {
                font-size: 68px;
            }
            .title-right {
                font-size: 28px;
                line-height: 36px;
            }
        }
    }
    @media (max-width: 1024px) and (min-width: 768px) {
        .content {
            .title-left {
                font-size: 80px !important;
            }
            p {
                max-width: 500px !important;
                font-size: 34px;
                line-height: 36px;
            }
        }
    }
    @media screen and (max-width: 960px) {
        .container {
            position: relative;
            overflow-x: hidden;
            padding: 35px 20px;
            height: initial;
        }
        p {
            font-size: 21px;
            line-height: 29px;
        }
        .camera {
            display: none;
        }
        .content {
            position: initial;
            margin-top: 0;
            display: flex;
            flex-direction: column-reverse;

            .title-left {
                text-align: center;
                position: initial;
                font-size: 58px;
            }
            .title-right, .arrow-top-left, .arrow-top-right, .arrow-bottom-right {
                display: none;
            }
            .top-left, .top-right, .bottom-right {
                position: initial;
            }
            .bottoms {
                margin-top: -50px;
                display: flex;
                align-items: center;
                gap: 35px;
                flex-direction: column;

                counter-reset: my-counter;

                p::before {
                    counter-increment: my-counter; 
                    content: counter(my-counter) ". ";
                }
            }
        }
        .puls {
            max-width: initial !important;
            width: 100vw;
            margin-left: -20px;
            margin-top: -50px;
        }
        .elem {
            width: 100%;
        }
    }
}