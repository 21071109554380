.followApp7 {
    .container-medium {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content {
        opacity: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 60px;
        .subtitle {
            h4 {
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
            }
            p {
                font-weight: 300;
                font-size: 13px;
                line-height: 16px;
            }
        }
        .title {
            max-width: 630px;
        }
    }

    .chart-container {
        position: relative;

        &__position {
            position: absolute;
            bottom: 110px;
        }

        .chart {
            margin-bottom: -50px;
            max-height: 50vh;
            @media screen and (min-height: 900px) {
                max-height: 60vh;
            }
        }
    }

    .chart-section {
        height: 45vh;
        position: absolute;
        bottom: 0;

        display: flex;
        gap: 15px;
        align-items: end;
        .chart-item {
            width: 17vh;

            -webkit-box-shadow: -7px 8px 0px 1px rgba(0, 0, 0, 1);
            -moz-box-shadow: -7px 8px 0px 1px rgba(0, 0, 0, 1);
            box-shadow: -7px 8px 0px 1px rgba(0, 0, 0, 1);
        }
        .chart-item2 {
            height: 40%;
        }

        transform: translateY(150%);

        @media screen and (max-height: 900px) {
            height: 42vh;
        }
    }

    .web-camera {
        position: absolute;
        top: 10%;
        right: 5%;

        border: 10px #000 solid;
        border-image: url(../img/IPAD.png) 40;
        border-image-width: 18px;
        border-radius: 20px;

        width: 21%;
        height: 29%;

        @media screen and (max-height: 900px) {
            border: 7px #000 solid;
            border-image: url(../img/IPAD.png) 40;
            border-image-width: 13px;
            border-radius: 15px;
            width: 20%;
            height: 30%;
        }
    }

    .elem-right {
        position: absolute;
        top: 260px;
        right: 0;

        width: 130px;
    }
    .elem-left {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 160px;
    }
    @media (max-height: 700px) and (max-width: 767px) {
        .container-medium {
            padding: 40px 0 0 !important;
        }
        .chart-container {
            &__position {
                position: initial !important;
            }
        }
    }
    @media (max-height: 580px) {
        .container-medium {
            position: relative;
            height: auto;
        }
    }
    @media (max-width: 1024px) and (min-width: 768px) {
        .container-medium {
            padding: 80px 90px 0;
        }
        .content {
            .divider {
                padding: 0 35px;
            }
        }
        .chart-container__position {
            bottom: 140px;
        }
    }
    @media screen and (max-width: 767px) {
        .container-medium {
            padding: 40px 30px 0;
            text-align: center;
        }
        .web-camera {
            width: auto;
            height: 40%;
        }
        .content {
            flex-direction: column;
            .subtitle {
                max-width: 150px;
            }
            .title {
                font-size: 22px;
                line-height: 27px;
            }
        }
        .divider {
            line-height: 110px;
        }
        .chart-container {
            &__position {
                position: absolute;
                bottom: 22vh;
            }
            .chart {
                margin-bottom: 0;
                height: 270px;
                object-fit: cover;
            }
        }
        .chart-section {
            height: 47vh;
            .chart-item {
                width: 80px;

                -webkit-box-shadow: -3px 5px 0px 1px rgba(0, 0, 0, 1);
                -moz-box-shadow: -3px 5px 0px 1px rgba(0, 0, 0, 1);
                box-shadow: -3px 5px 0px 1px rgba(0, 0, 0, 1);
            }
            .chart-item {
                height: 65%;
            }
        }
        .elem-right {
            display: none;
        }
        .elem-left {
            display: none;
        }
    }
    @media screen and (max-height: 760px) {
        .divider {
            line-height: 60px;
        }
    }
}