.followApp14 {
    .assistant {
        scale: 0;
        position: absolute;
        bottom: 26%;
        right: 15%;
        width: 89vh;

        img {
            position: relative;
        }
        p {
            font-weight: 300;
            font-size: 4vh;
            line-height: 5vh;
            text-align: center;

            position: absolute;
            top: 24%;
            left: 8%;

            b {
                font-weight: 700;
            }
        }
    }
    .text {
        font-weight: 300;
        font-size: 35px;
        line-height: 39px;
        text-align: center;
        max-width: 700px;
        position: absolute;
        left: 50%;
        bottom: 80px;
        transform: translateX(-50%);

        b {
            font-weight: 700;
        }
    }

    .elem {
        position: absolute;
        top: 0;
        left: 0;
        height: 102%;
        z-index: -1;
    }

    @media screen and (max-width: 900px) {
        .assistant {
            width: 70vh;
            top: 10%;
            bottom: auto;
            right: 10%;
            p {
                font-size: 3vh;
                line-height: 4vh;
            }
        }
    }
    @media (max-width: 1024px) and (min-width: 768px) {
        .assistant {
            right: 5%;
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            height: 100dvh;
            position: relative;
            overflow-x: hidden;
            padding: 0 10px;
        }
        .assistant {
            top: 20%;
            right: auto;
            left: 50%;
            width: 450px;

            p {
                font-size: 100%;
                line-height: 140%;
                top: 10%;
                left: 17%;
                max-width: 65%;
            }
        }
        .text {
            font-size: 21px;
            line-height: 28px;

            max-width: 350px;
            width: 100%;
        }
        .elem {
            max-height: 450px;
            right: 0;
            left: auto;
        }
    }
}