$colorBlack: #000;
$colorWhite: #FFF;
$colorGrey: #666;
$colorLightGrey: #999;
$colorDarkGrey: #333;
$colorGreen: #0C0;
$colorLightGreen: lighten($colorGreen, 10);
$colorDarkGreen: darken($colorGreen, 10);
$colorRed: #C00;
$colorLightRed: lighten($colorRed, 10);
$colorDarkRed: darken($colorRed, 10);

html,
body {
	height: 100dvh;
}
body {
	background: $colorWhite;
	color: $colorBlack;
	font-size: 14px;
	font-family: 'Signika', sans-serif;
	font-weight: 300;
	font-size: 25px;
	line-height: 31px;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;
}
* {
	outline: none;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba($colorBlack, 0);
	&:before,
	&:after {
		box-sizing: border-box;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1em;
	line-height: 1;
	margin: 0;
}
h1 {
	font-weight: 700;
	font-size: 85px;
	line-height: 105px;
}
h2 {
	font-weight: 700;
	font-size: 59px;
	line-height: 73px;
}
h3 {
	font-weight: 700;
	font-size: 50px;
	line-height: 62px;
}
h4 {
	font-weight: 700;
	font-size: 40px;
	line-height: 51px;
}
img {
	vertical-align: top;
	max-width: 100%;
	pointer-events: none;
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
ul,
ol {
	list-style-type: none;
	list-style-position: inside;
	padding: 0;
	margin: 0;
}
p {
	margin: 0;
}
button {
	outline: none;
	&:focus {
		outline: none;
	}
}
[class^='icon-'],
[class*=' icon-'] {
	position: relative;
	vertical-align: middle;
}
// include @extend %image-bg;
%image-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 320px;
	min-height: 100%;
	overflow: hidden;
}

.slide {
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;

	position: relative;
}
.slide-inner {
	max-width: 1440px;
	margin: 0 auto;
}
.container {
	width: 100%;
	height: 100dvh;
	padding: 70px 80px;
}
.container-small {
	width: 100%;
	height: 100dvh;
	padding: 50px 0;
	max-width: 1020px;
	margin: 0 auto;
}
.container-medium {
	width: 100%;
	height: 100dvh;
	padding: 80px 170px 0 170px;
}
.container-large {
	width: 100%;
	height: 100dvh;
	padding: 90px 110px;
}

.web-camera {
	border: 15px #000 solid;
	border-image: url('../img/IPAD.png') 30;
	border-image-width: 20px;
	border-radius: 25px;

	width: 360px;
	height: 280px;
}

.answers-everything {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.answer-everything {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 367px;
	height: 102px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 24px;
	line-height: 48px;
	border: 20px #fff solid;
	border-image-width: 20px;

	&__yellow {
		border-image: url('../img/answer-yellow.png') 40 50;
		color: #F9BB3F;
	}
	&__blue {
		border-image: url('../img/answer-blue.png') 40 50;
		color: #1A9BC2;
	}
	&__red {
		border-image: url('../img/answer-red.png') 40 50;
		color: #EA2082;
	}
	&__green {
		border-image: url('../img/answer-green.png') 40 50;
		color: #46E577;
	}
}
.hidden{
	display:none;
}
.error {
	&.has-error {
		border: 2px solid #c00;
	}
}

@media (max-width: 1024px) and (min-width: 768px) {
	.container-small {
		padding: 50px;
	}
}
@media screen and (max-width: 767px) {
	.answers-everything {
		flex-direction: column;
		gap: 25px;
		align-items: center;
	}
	.answer-everything {
		width: 170px;
		height: 48px;
		border-width: 10px;

		font-size: 12px;
	}
	.container {
		height: auto;
		padding-bottom: 100px !important;
	}
	.container-small {
		height: auto;
		padding-bottom: 100px !important;
	}
	.container-medium {
		height: auto;
		padding-bottom: 100px !important;
	}
	.container-large {
		height: auto;
		padding-bottom: 100px !important;
	}
}
