::placeholder {
	color: $colorLightGrey;
	opacity: 1;
}
input,
textarea {
	position: relative;
	background: $colorWhite;
	color: $colorBlack;
	width: 100%;
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	border: 1px solid $colorGrey;
	border-radius: 0;
	padding: 0 20px;
	transition: border-color 0.3s ease;
	appearance: none;
	&:hover {
		border-color: $colorDarkGrey;
	}
	&:active,
	&:focus {
		&:not(.btn) {
			&:not([disabled]) {
				&::placeholder {
					color: transparent;
				}
			}
		}
	}
	&[disabled],
	&.disabled {
		opacity: 0.5;
	}
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px $colorWhite inset;
		transition: background-color 5000s ease-in-out 0s;
	}
}
input {
	height: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
}
textarea {
	height: 120px;
	padding: 15px 20px;
	resize: none;
}
.has {
	&-error {
		input,
		textarea {
			border-color: $colorRed !important;
		}
		.help-block {
			opacity: 1;
		}
	}
	&-success {
		input,
		textarea {
			//border-color: $colorGreen !important;
		}
	}
}
label {
	display: block;
	margin-bottom: 0;
}
.form {
	&-title {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	&-body {
		position: relative;
	}
	&-text {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	&-group {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	&-flex {
		display: flex;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		.form {
			&-group {
				flex: 1 0 0;
				&:not(:last-child) {
					margin-right: 20px;
					margin-bottom: 0;
				}
			}
		}
	}
	&-button {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}
.checkbox {
	position: relative;
	display: inline-block;
	[type="checkbox"],
	[type="radio"] {
		position: absolute;
		left: -9999px;
		width: inherit;
		height: inherit;
		& ~ label {
			position: relative;
			display: inline-block;
			min-height: 20px;
			color: $colorBlack;
			line-height: 20px;
			padding-left: 20px;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				background: $colorWhite;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				border: 1px solid $colorGrey;
				transition: border-color 0.3s ease;
			}
			&:after {
				content: '';
				position: absolute;
				transition: opacity 0.3s ease;
				opacity: 0;
			}
			&:hover {
				&:before {
					border-color: $colorDarkGrey;
				}
			}
			&:not(:empty) {
				padding-left: 35px;
			}
		}
		&:disabled {
			& ~ label {
				&:before {
					opacity: 0.5;
				}
			}
		}
		&:checked {
			& ~ label {
				//&:before {
				//
				//}
				&:after {
					opacity: 1;
				}
			}
			&:disabled {
				& ~ label {
					&:before {
						opacity: 0.5;
					}
					&:after {
						opacity: 0.5;
					}
				}
			}
		}
	}
	[type="checkbox"] {
		& ~ label {
			&:before {
				background: $colorGrey;
				top: 4px;
				left: 4px;
				width: 12px;
				height: 12px;
				border-radius: 0;
			}
			&:after {
				background: $colorGrey;
				top: 4px;
				left: 4px;
				width: 12px;
				height: 12px;
			}
		}
	}
	[type="radio"] {
		& ~ label {
			&:before {
				background: $colorGrey;
				top: 4px;
				left: 4px;
				width: 12px;
				height: 12px;
				border-radius: 50%;
			}
			&:after {
				border-radius: 50%;
			}
		}
	}
	&.has {
		&-error {
			[type="checkbox"],
			[type="radio"] {
				& ~ label {
					&:before {
						border-color: $colorRed !important;
					}
				}
			}
		}
	}
}
.help-block {
	position: absolute;
	top: 100%;
	left: 0;
	color: $colorRed;
	font-size: 12px;
	line-height: 1.25;
	transition: opacity 0.5s;
	pointer-events: none;
	opacity: 0;
}
