.followApp8 {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
        max-height: calc(100vh - 100px);
        margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
        .container {
            padding: 0;
        }
        img {
            height: auto;
            max-height: initial;
        }
    }
}