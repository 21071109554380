.followApp2 {
    .assistant {
        scale: 0;
        position: absolute;
        bottom: 20px;
        left: 30px;

        width: 560px;

        img {
            position: relative;
        }
        p {
            font-size: 20px;
            line-height: 25px;
            position: absolute;
            top: 30px;
            left: 50px;
            width: 380px;
        }
        @media screen and (max-width: 767px) {
            bottom: 90px;
            left: 15px;
            width: 320px;

            p {
                font-size: 12px;
                line-height: 15px;
                top: 12px;
                left: 43px;
                width: 200px;
            }
        }
    }
}