.followApp17 {
    .container {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 120px;
        text-align: center;
    }
    h3 {
        font-weight: 500;
        line-height: 75px;
        margin-bottom: 35px;
    }
    form {
        display: flex;
        flex-direction: column;

        width: 100%;

        h4 {
            font-weight: 500;
            font-size: 34px;
            line-height: 75px;
            text-align: left;
        }
    }
    input, textarea {
        background: #D9D9D9;
        color: #fff;
        border: none;

        transition: all 0.4s;
    }
    .text {
        border-radius: 22px;

        padding: 10px 15px;
        margin-bottom: 15px;

        font-weight: 500;
        font-size: 34px;
        line-height: 75px;

        &::placeholder {
            color: #fff;
        }
    }
    .message {
        height: 260px;
        border-radius: 22px;

        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
    }
    .send {
        width: 130px;
        height: 35px;
        
        border-radius: 14px;

        font-weight: 500;
        font-size: 30px;
        line-height: 0;

        margin-top: 20px;
        margin-left: auto;
        cursor: pointer;

        &:hover {
            background: #c3c3c3;
        }
    }
    .thank {
        font-family: 'Delight Winter';
        font-size: 34px;
        line-height: 75px;
        margin-bottom: 20px;
    }
    .container930 {
        max-width: 930px;
        .logo {
            min-width: 190px;
            padding: 55px 0 35px;
        }
    }
    .container610 {
        width: 610px;
        .social {
            margin-top: 35px;
            margin-bottom: 75px;
            display: flex;
            justify-content: space-between;
            img {
                max-height: 78px;
            }
        }
        .sites {
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            a {
                width: 33%;
            }
            img {
                max-height: 105px;
            }
            p {
                text-align: left;
                margin-top: 12px;
                font-weight: 700;
                font-size: 17px;
                line-height: 19px;
                color: #000;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            padding: 60px;
        }
        h3 {
            font-size: 40px;
            line-height: 57px;        
        }
        form {
            h4 {
                font-size: 31px;
                line-height: 50px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            padding: 20px 15px 90px;
        }

        h3 {
            font-size: 14px;
            line-height: 19px;
        }
        form {
            h4 {
                font-size: 13px;
                line-height: 22px;
                margin-bottom: 15px;
            }
        }
        .text {
            font-size: 17px;
            line-height: 22px;
        }
        .container930 {
            h4 {
                font-size: 17px;
                line-height: 26px;
            }
            img {
                max-width: 190px;
                padding: 30px 0 40px;
            }
        }
        .container610 {
            width: auto;
            .social {
                padding: 0 10px;
                margin-top: 15px;
                margin-bottom: 40px;
                img {
                    max-height: 32px;
                }
            }
            h4 {
                font-size: 21px;
                line-height: 51px;
            }

            .sites {
                margin-top: 0;

                a {
                    width: auto;
                    min-width: 27%;
                }
                img {
                    max-height: 50px;
                }
                p {
                    font-size: 7px;
                    line-height: 19px;

                    br {
                        display: none;
                    }

                    span {
                        font-size: 17px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}