.btn {
	position: relative;
	background: $colorBlack;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	color: $colorWhite;
	font-size: 14px;
	font-weight: 400;
	font-family: "Open Sans", sans-serif;
	line-height: 1.25;
	text-align: center;
	text-decoration: none;
	border: 0;
	border-radius: 8px;
	box-shadow: none;
	padding: 0 15px;
	transition: background 0.3s ease;
	outline: none;
	cursor: pointer;
	&:hover,
	&:active {
		color: $colorWhite;
		text-decoration: none;
		outline: none;
	}
	&:focus {
		box-shadow: none;
		outline: none;
	}
	&-green {
		background: $colorGreen;
		color: $colorWhite;
		//&:hover,
		//&:active {
		//
		//}
	}
	&.disabled,
	&[disabled] {
		pointer-events: none;
		opacity: 0.5;
		cursor: auto;
	}
}

.arrow_right, .arrow_left {
	transition: transform 250ms;
}
.fp-custom-arrow {
	background-color: inherit;
	border: none;
	cursor: pointer;

	position: absolute;
	bottom: 20px;

	width: fit-content;
	svg {
		width: 83px;
		height: 83px;
	}

	@media screen and (max-width: 767px) {
		svg {
			width: 32px;
			height: 34px;
			overflow: initial;

			path {
				stroke-width: 8;
			}
		}
	}
	&.left {
		left: 30px;
	}
	&.right {
		right: 30px;
	}

	&:hover {
		.nav_arrow {
			.arrow_right {
				transform: translateX(5px)
			}
			.arrow_left {
				transform: translateX(-5px)
			}
		}
	}
}