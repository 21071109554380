.followApp13 {
    h3 {
        text-align: center;
        font-weight: 700;
        font-size: 56px;
    }
    .content {
        margin-top: 60px;
        display: flex;
        gap: 10px;
        align-items: center;

        .camera {
            position: relative;
        }
        .assistant-smile {
            scale: 0;
            position: absolute;
            bottom: -76%;
            left: 0;
            max-width: 190px;
            width: 19vh;
        }

        .arrows {
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .arrow-right {
            transform: rotate(15deg);
        }
        .arrow-left {
            transform: rotate(165deg);
        }

        .mem {
            max-width: 625px;
            width: 43vw;
        }
        @media screen and (max-height: 900px) {
            .mem {
                width: 30vw;
            }
            .assistant-smile {
                bottom: -55%;
            }
            .arrows {
                height: 250px;
            }
        }
    }
    .assistant-smile-mobile {
        display: none;
    }

    .elem {
        position: absolute;
        top: 0;
        right: 150px;
        height: 102%;
        z-index: -1;
    }

    @media screen and (max-width: 900px) {
        .web-camera {
            width: auto;
            height: 235px;
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            padding: 20px 15px;
        }
        h3 {
            font-size: 35px;
            line-height: 46px;
            text-align: left;
            padding: 0 15px;
        }
        .content {
            margin-top: 45px;
            flex-direction: column;
            gap: 45px;

            .mem {
                width: 100%;
                max-width: 400px;
            }
            .assistant-smile {
                display: none;
            }
        }
        .assistant-smile-mobile {
            display: block;
            max-width: 120px;
        }
        .elem, .arrows {
            display: none !important;
        }
    }
}