.followApp11 {
    .container {
        padding: 80px 45px 45px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            font-family: Free Pen; 
            font-weight: 400;
            font-size: 154px;
            line-height: 48px;
        }

        .arrows {
            max-width: 1000px;
            display: flex;
            gap: 120px;
            justify-content: space-between;

            margin-top: 85px;
            margin-bottom: 30px;
        }

        .arrows-answers {
            min-width: 1000px;
            display: flex;
            justify-content: space-between;

            .inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    margin-top: 5px;
                    margin-bottom: 20px;
                }
            }
        }

        .assistant {
            scale: 0;
            position: absolute;
            width: 100vh;
            bottom: -4vh;
            right: 7vw;
    
            img {
                position: relative;
            }
            p {
                text-align: center;
                font-weight: 300;
                font-size: 3.8vh;
                line-height: 123%;
                position: absolute;
                bottom: 18%;
                left: 9%;
                width: 60%;
            }
        }
        .elem {
            position: absolute;
            bottom: 0;
            right: 0;
            max-height: 400px;
        }
        @media screen and (max-width: 1080px) {
            position: relative;
            overflow-x: hidden;
            h2 {
                font-size: 100px;
            }
            .arrows {
                min-width: 1000px;
            }
        }
        @media screen and (max-height: 900px) {
            .assistant {
                width: 80vh;
                bottom: -4vh;
                right: 7vw;

                p {
                    font-size: 3.1vh;
                }
            }
        }
        @media (max-width: 1024px) and (min-width: 768px) {
            .answers-everything {
                gap: 15px;
            }
            .assistant {
                width: 60vh;
                p {
                    font-size: 2.3vh !important;
                }
            }
        }
        @media screen and (max-width: 767px) {
            position: relative;
            overflow-x: hidden;
            padding: 40px 30px;
            height: 100dvh;

            h2 {
                font-size: 53px;
                margin-bottom: 15px;
            }
            .arrows {
                display: none;
            }
            .arrows-answers {
                min-width: auto;
                margin-top: 25px;

                .left-b {
                    display: none;
                }
                .center {
                    display: none;
                }
                .right-b {
                    display: none;
                }
            }
            .assistant {
                width: 49vh;
                bottom: auto !important;
                top: 400px;
                right: auto;
                left: 50%;

                p {
                    bottom: 35%;
                    left: 11%;
                    width: 70%;
                    font-size: 2.1vh;
                }
            }
            .elem {
                display: none;
            }
        }
        @media screen and (max-width: 450px) {
            .assistant {
                right: -6vw;
            }
        }
    }
}