.followApp4 {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    h1 {
        opacity: 0;
    }
    p {
        opacity: 0;
        margin-top: 48px;
        max-width: 90%;
    }
    img {
        margin-bottom: -50px;
        max-height: 50vh;
        @media screen and (min-height: 900px) {
            max-height: 60vh;
        }
    }
    @media (max-width: 1024px) and (min-width: 768px) {
        .container {
            justify-content: space-around;
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            padding: 20px 30px;
            justify-content: initial;
        }
        h1 {
            font-size: 40px;
            line-height: 49px;
        }
        p {
            margin-top: 18px;
            font-size: 20px;
            line-height: 25px;
        }
        img {
            margin-bottom: 0;
            margin-top: 40px;
            max-height: initial;
        }
    }
}