.followApp3 {
    .container-small {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .zoom {
        position: relative;

        .mac {
            max-height: calc(100vh - 100px);
        }
    }
    .messenger {
        max-height: 52%;
        overflow-y: auto;
        position: absolute;
        top: 7%;
        right: 5%;
        width: 17%;
        height: fit-content;

        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .message {
        opacity: 0;
        padding: 3px;
        display: flex;
        align-items: start;
        gap: 5px;
        border-radius: 5px;
        background-color: #FFF;

        p {
            font-weight: 300;
            font-size: 11px;
            line-height: 14px;

            span {
                color: #3E892B;
            }

            padding: 2px 10px 2px 0;
        }
    }

    @media screen and (max-width: 767px) {
        .container-small {
            height: initial;
            padding: 90px 15px;
            align-items: initial;
        }
        .mac {
            width: 100%;
            height: auto !important;
            min-height: auto !important;
        }
        .messenger {
            padding-top: 15px;
            position: initial;
            max-height: initial;
            width: 80%;
            margin: 0 auto;

            .message {
                img {
                    width: 27px;
                    height: 27px;
                }
                p {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }

    @media screen and (min-width: 1440px) {
        .container-small {
          max-width: 1990px;
        }
        .zoom {
            .mac {
                max-height: 1020px;
                height: calc(100vh - 300px);
            }
        }
    }
}