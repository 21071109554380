.startSeite {
    .start_title {
        opacity: 0;
        max-width: 410px;
    }
    .start_img {
        position: absolute;
        bottom: 135px;
        right: 55px;
        height: calc(100% - 150px);
        object-fit: cover;
        object-position: right;
        z-index: 2;
    }
    .start_scene {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 242px;
        object-fit: cover;
    }

    .fp-custom-arrow {
        display: flex;
        align-items: center;
        gap: 10px;
        
        .divider {
            width: 45px;
            border: 1px solid #000000;
        }
        p {
            color: #000;
            font-weight: 500;
            font-size: 26px;
            line-height: 75px;
        }
        &.right {
            left: 110px;
            bottom: 30px;
        }
    }

    @media screen and (max-width: 767px) {
        .container-large {
            padding: 15px 20px;
            height: auto;
        }
        .start_title {
            font-weight: 700;
            font-size: 47px;
            line-height: 58px;
        }
        .start_img {
            right: 0px;
            bottom: 190px;
            max-height: 390px;
            object-position: 86%;
        }
        .start_scene {
            height: 270px;
            object-position: -770px;
        }
        .fp-custom-arrow {
            width: 100%;
            flex-direction: row-reverse;
            &.right {
                left: initial;
                right: 35px;
                bottom: 35px;
            }
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: initial;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1100px) {
        .start_img {
            max-width: initial;
            right: -30px;
        }
    }
}