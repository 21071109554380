.followApp5 {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    img {
        position: absolute;
        top: 0;
        right: 0;
        width: 21vw;
    }
    h1 {
        opacity: 0;
    }
    .subtitle {
        opacity: 0;
        font-weight: 400;
        font-size: 19px;
        line-height: 23px;
        margin-top: -10px;
        margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
        .container {
            padding: 120px 10px;
        }
        h1 {
            font-size: 39px;
            line-height: 49px;
        }
        .subtitle {
            font-size: 9px;
            line-height: 12px;
            margin-top: 5px;
        }
        .web-camera {
            width: auto;
            height: 250px;
        }
        .quiz {
            margin-top: 50px;
            grid-template-columns: initial;

            .quiz-item {
                display: none;

                p {
                    font-size: 23px;
                    line-height: 28px;
                }
            }
        }
        img {
            height: 120px;
            width: auto;
        }
    }
}

.quiz {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5em;

    .quiz-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        p {
            font-weight: 300;
            font-size: 25px;
            line-height: 31px;
        }
        text-align: center;

        .answer {
            display: flex;
            align-items: center;
            width: 215px;
            max-height: 48px;
            padding: 8px 10px;
            margin-top: 20px;
            border: 1px solid #A0A0A0;
            border-radius: 24px;
            cursor: pointer;
            transition: all 0.3s linear;

            .variant {
                width: 32px;
                height: 32px;

                color: #A0A0A0;
                font-weight: 400;
                font-size: 20px;
                text-transform: uppercase;

                display: flex;
                align-items: center;
                justify-content: center;

                border: 1px solid #A0A0A0;
                border-radius: 100%;
                transition: all 0.3s linear;
            }
            .variant-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 25px;

                margin: 0 auto;
            }

            &.active {
                background-color: #F20709 !important;
                color: #fff;
                .variant {
                    color: #fff;
                    border-color: #fff;
                }
            }
            &.correct-answer.active {
                background-color: #18BF19 !important;
                color: #000;
                .variant {
                    color: #000;
                    border-color: #000;
                }
            }

            &:hover {
                background-color: #dadada;
            }
        }
        .answers{
            &.close {
                .answer:hover {
                    background: none;
                }
            }
        }
    }
}