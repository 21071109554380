.followApp16 {
    .container {
        height: auto;
        padding: 80px 130px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h3 {
        opacity: 0;
        text-align: center;
        font-weight: 500;
        font-size: 50px;
        line-height: 75px;
        margin-bottom: 40px;
    }
    .buttons {
        max-width: 800px;
        margin-top: 60px;
        display: flex;
        gap: 15px;
        row-gap: 40px;
        justify-content: center;
        flex-wrap: wrap;
        .button {
            position: relative;
            display: flex;
            width: 360px;
            height: 125px;
            border-radius: 100px;
            background: linear-gradient(178.85deg, #FAFAFA 3.6%, #DCDCDC 50.28%);
            background-size:1px 180px;

            transition: all 0.4s;
            cursor: pointer;

            &:hover {
                background-position: 20px; 

                .text {
                    zoom: 1.05;
                }
            }
            &:before {
                position: absolute;
                content: "";
                width: 83%;
                height: 32px;
                border-radius: 50%;
                left: 30px;
                bottom: 0px;
                box-shadow: 0 12px 20px -7px rgba(103,103,103,.9490196078);
                z-index: -1;
            }

            img {
                padding: 20px 5px 20px 25px;
            }
            .text-container {
                text-align: center;
                width: 100%;
                height: 100%;
                padding: 10px;
                padding-left: 0;

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    border-radius: 100px;
                    background: linear-gradient(179.12deg, #DCDCDC 3.6%, #FAFAFA 93.28%);
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 39px;

                    b {
                        font-size: 50px;
                    }
                }
            }
        }
        @media screen and (max-height: 1000px) {
            .button {
                height: 90px;
            }
        }
        @media screen and (max-width: 1010px) {
            .button {
                width: 280px;
            }
        }
        @media screen and (max-height: 900px) {
            margin-top: 30px;
        }
    }
    .subbutton {
        margin-top: 35px;
        background: #D9D9D9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 23px;
        transition: all 0.4s;
        cursor: pointer;

        &:hover {
            background: #bbbbbb;
        }

        .text {
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;

            padding: 4px 35px;
        }
    }

    .elem {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    }

    @media screen and (max-height: 900px) {
        h3 {
            margin-bottom: 30px;
        }
        .web-camera {
            width: auto;
            height: 26vh;
        }
    }
    @media screen and (max-width: 900px) {
        .container {
            padding: 80px 90px 40px;
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            padding: 20px;
            justify-content: center;
        }
        h3 {
            font-size: 24px;
            line-height: 30px;
        }
        .buttons {
            max-width: 400px;
            gap: 6px;
            row-gap: 17px;
            .button {
                width: 150px;
                height: 55px;
                img {
                    padding: 10px 4px 10px 13px;
                }
                &:hover {
                    .text {
                        zoom: initial;
                    }
                }
                .text-container {
                    padding: 4px;
                    .text {
                        text-align: center;
                        font-size: 18px;
                        line-height: 21px;
                        b {
                            font-size: 35px;
                            line-height: 39px;
                        }
                    }
                }
            }
        }
        .subbutton {
            .text {
                font-size: 26px;
                line-height: 39px;
            }
        }

        .elem {
            display: none;
        }
    }
}