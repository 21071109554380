.followApp15 {
    .container-medium {
        display: flex;
        align-items: flex-end;

        .message {
            opacity: 0;
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            .counter {
                position: absolute;
                top: -16px;
                right: 20px;

                width: 32px;
                height: 32px;
                color: #fff;
                font-weight: 300;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #BD2525;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 100%;
                z-index: 1;
            }
            .message-inner {
                position: relative;
                padding: 15px 25px;
                border-radius: 10px;
                font-size: 20px;
                line-height: 25px;
                width: 400px;
                background-color: #fff;
            }
        }
    }
    img {
        max-height: 900px;
        height: 100%;
        margin: 0 auto;
    }

    @media screen and (max-width: 1200px) {
        img {
            height: auto;
        }
    }
    @media screen and (max-width: 900px) {
        .container-medium {
            padding: 80px;
            padding-bottom: 0;
        }
    }
    @media screen and (max-width: 767px) {
        .container-medium {
            height: 100dvh;
            padding: 0 !important;

            .message {
                bottom: 120px;
                .counter {
                    width: 25px;
                    height: 25px;
                }
                .message-inner {
                    padding: 15px;
                    width: 310px;

                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        img {
            height: 45%;
            object-fit: cover;
        }
    }
}